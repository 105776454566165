@import '~antd/dist/antd.less'; // Import Ant Design styles by less entry
@import '~antd/lib/style/themes/default.less'; // Import Ant Design styles by less entry


@darkBlue: #1F3D7B;
@lightBlue: #0183ff;

@darkBlue2: #00305E;
@lightBlue2: #7CBDFC;

@gold: #FFA624;
@lightGold: #FFE5A9;

@darkGrey: #333E48;
@lightGrey: #BBC1C7;

@lightGreen: #6FC102;

@orange: #FF7600;

@primary-color: @darkBlue; // primary color for all components
@link-color: #5C987A; // link color
@success-color: #52c41a; // success state color
@warning-color: #faad14; // warning state color
@error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
@heading-color: @darkBlue; // heading text color
@text-color: @darkGrey; // major text color
@text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
@disabled-color: @lightGrey; // disable state color
@border-radius-base: 15px; // major border radius
@border-color-base: @lightBlue; // major border color
@box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers


body {
  margin: 0;
  font-family: 'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 15px;
  line-height: 23px;
  min-width: 375px;
}

h1, h1.ant-typography {
  font-size: 50px;
  line-height: 58px;
  font-weight: 700;
}

h2, h2.ant-typography {
  font-size: 30px;
  line-height: 38px;
  font-weight: 400;
}

h3, h3.ant-typography {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
}

h4, h4.ant-typography {
  font-size: 15px;
  line-height: 23px;
  font-weight: 800;
}

a {
  color: #0183FF;
}
.ant-layout-content > .ant-row {
  margin: 40px 0;
}

.ant-layout-content > .ant-row:first-child {
  margin-top: 0;
}

a.ant-btn, .ant-btn {
  width: 100%;
  height: 36px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 800;
  padding: 9px 0;
  border: none;
  border-radius: 18px;
  text-align: center;
}

.ant-popover-inner {
  border-radius: 0;
}

.ant-space {
  width: 100%;
}

@media (min-width: @screen-xs-min) {
  h1, h1.ant-typography {
    font-size: 36px;
    line-height: 44px;
  }

  h2, h2.ant-typography {
    font-size: 30px;
    line-height: 38px;
  }

  h3, h3.ant-typography {
    font-size: 20px;
    line-height: 28px;
  }

  h4, h4.ant-typography {
    font-size: 15px;
    line-height: 23px;
  }
}

@media (min-width: @screen-sm-min) {
  h1, h1.ant-typography {
    font-size: 36px;
    line-height: 44px;
  }

  h2, h2.ant-typography {
    font-size: 30px;
    line-height: 38px;
  }

  h3, h3.ant-typography {
    font-size: 20px;
    line-height: 28px;
  }

  h4, h4.ant-typography {
    font-size: 15px;
    line-height: 23px;
  }
}

@media (min-width: @screen-md-min) {
  h1, h1.ant-typography {
    font-size: 36px;
    line-height: 44px;
  }

  h2, h2.ant-typography {
    font-size: 30px;
    line-height: 38px;
  }

  h3, h3.ant-typography {
    font-size: 20px;
    line-height: 28px;
  }

  h4, h4.ant-typography {
    font-size: 15px;
    line-height: 23px;
  }
}

@media (min-width: @screen-lg-min) {
  h1, h1.ant-typography {
    font-size: 50px;
    line-height: 58px;
  }

  h2, h2.ant-typography {
    font-size: 30px;
    line-height: 38px;
  }

  h3, h3.ant-typography {
    font-size: 20px;
    line-height: 28px;
  }

  h4, h4.ant-typography {
    font-size: 15px;
    line-height: 23px;
  }
}

.btn-primary() {
  .button-color(white; @darkBlue; transparent);

  text-shadow: @btn-text-shadow;
  box-shadow: @btn-primary-shadow;

  &:hover,
  &:active,
  &:focus {
    .button-color(white; @lightBlue; transparent);
  }
  .button-disabled();
}

